.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  font-size: 1.2rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.1rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.3rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.5rem;
  }
}

.my-background1 {
  background: #557A95;
}

.my-background2 {
  background: #B0A295;
}

.my-background3 {
  background: #5D5C61;
}

.Lspacing {
  letter-spacing: 2px;
}

.navbar .nav-pills .active {
   color: Black ;
   font-weight:bold;
}

.nav-color{
   background: #c9bfb6;
}
